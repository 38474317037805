<template>
    <div class="create-admin">
        <div class="create-admin__wrapper container">
            <div class="create-admin__header">
                <VBreadcrumbs class="create-admin__header-breadcrumbs"/>
                <div class="create-admin__header-row">
                    <h1 class="create-admin__header-title main-title" :class="{'create-admin__header-title_edit': editing}">
                        {{ title }}</h1>
                    <VButton
                        label="Удалить администратора"
                        autoSize
                        bg="transparent"
                        bgHover="#E4E7EE"
                        color="#DD4646"
                        colorHover="#DD4646"
                        @click="showDeleteAdminPopup = true"
                        v-if="editing && userId !== adminId"
                        class="create-admin__delete-admin">
                        <template #iconLeft>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 7.3335H6.55556H19" stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M17.4446 7.33328V18.2222C17.4446 18.6347 17.2807 19.0304 16.9889 19.3221C16.6972 19.6138 16.3016 19.7777 15.889 19.7777H8.11122C7.69866 19.7777 7.303 19.6138 7.01128 19.3221C6.71955 19.0304 6.55566 18.6347 6.55566 18.2222V7.33328M8.889 7.33328V5.77772C8.889 5.36516 9.05289 4.9695 9.34461 4.67778C9.63633 4.38606 10.032 4.22217 10.4446 4.22217H13.5557C13.9682 4.22217 14.3639 4.38606 14.6556 4.67778C14.9473 4.9695 15.1112 5.36516 15.1112 5.77772V7.33328"
                                    stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </template>
                    </VButton>
                </div>
            </div>
            <MainBlock
                class="create-admin__main-block"
                :editing="!!route.params.id"
                :errorEmailText="errorEmailText"
                v-model="form"/>
            <SettingsBlock class="create-admin__settings-block" admin v-model="form" v-if="userId !== adminId"/>

            <div class="create-admin__btns">
                <VButton
                    label="Отменить"
                    class="create-admin__btn"
                    bg="#E4E7EE"
                    bgHover="#BBC4CD"
                    color="#1E3959"
                    colorHover="#1E3959"
                    @click="showPopup = true"/>

                <VButton
                    v-if="editing"
                    label="Сохранить"
                    :disabled="disabled"
                    clickEffect
                    class="create-admin__btn"
                    @click="submit"/>

                <VButton
                    v-else
                    label="Добавить"
                    :disabled="disabled"
                    clickEffect
                    class="create-admin__btn"
                    @click="submit"/>
            </div>
            <ModalWindow v-if="showPopup" @close="showPopup = false" class="create-admin__modal">
                <CancelChanges @close="showPopup = false" @cancelChanges="router.push(`/super-admins`)"/>
            </ModalWindow>
            <ModalWindow v-if="showDeleteAdminPopup" @close="showDeleteAdminPopup = false"
                         class="create-admin__modal-delete-admin">
                <DeletingEmployee
                    title="Удаление администратора"
                    description="Вы уверены что хотите удалить администратора?"
                    @close="showDeleteAdminPopup = false"
                    @no="showDeleteAdminPopup = false"
                    @delete="deleteAdmin"/>
            </ModalWindow>
        </div>
    </div>
</template>

<script setup>
import {computed, onActivated, onDeactivated, onMounted, reactive, ref, watch, watchEffect} from "vue";
import {useRouter, useRoute} from "vue-router";
import DeletingEmployee from '@/components/ModalWindow/ModalBodyes/DeletingEmployee'

import admins from "@/api/admins";
import other from "@/api/other";

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import MainBlock from '@/components/CreateEmployeeAndAdmin/MainBlock'
import SettingsBlock from '@/components/CreateEmployeeAndAdmin/SettingsBlock'
import CancelChanges from '@/components/ModalWindow/ModalBodyes/CancelChanges'

const router = useRouter();
const route = useRoute();

const editing = ref(!!route.params.id)
const title = ref(editing.value ? 'Редактировать администратора' : 'Новый администратор')

const showPopup = ref(false)
const showDeleteAdminPopup = ref(false)
const userId = ref(null)
const adminId = ref(null)

const errorEmailText = ref('')


const form = reactive({
    name: '',
    email: '',
    password: '',
    access: 0
})
watch(() => form.email, () => {
    errorEmailText.value = ''
})

const disabled = ref(false)

const requiredPassword = (val) => {
    if (editing.value) return true
    else return !!val
}

const rules = computed(() => ({
    name: {required},
    email: {required},
    password: {requiredPassword},
}));
const v$ = useVuelidate(rules, form);


onMounted(() => {
    editing.value = !!route.params.id
    title.value = editing.value ? 'Редактировать администратора' : 'Новый администратор'
    getUser()
    getAdmin()
})

onDeactivated(() => {
    userId.value = null
    adminId.value = null
    form.email = ''
    form.name = ''
    form.access = 0
})

const getUser = async () => {
    try {
        const response = await other.getUser()
        userId.value = response.id
    } catch (error) {
        console.log(error)
    }
}
const getAdmin = async () => {
    if (!editing.value) return

    try {
        const response = await admins.getAdmin(route.params.id)
        adminId.value = response.id
        form.email = response.email
        form.name = response.full_name
        form.access = response.is_blocked
    } catch (error) {
        console.log(error)
    }
}
const submit = async () => {
    const validate = await v$.value.$validate();
    if (!validate) return
    disabled.value = true

    const body = {
        full_name: form.name,
        email: form.email,
        password: form.password,
        is_blocked: form.access,
    }

    try {
        if (!editing.value) {
            await admins.postAdmin(body)
        } else {
            await admins.putAdmin(route.params.id, body)
        }
        await router.push(`/super-admins`);
    } catch (error) {
        console.log(error)
        errorEmailText.value = error?.response?.data?.errors?.email[0]
        disabled.value = false
    }
}
const deleteAdmin = async () => {
    try {
        await admins.deleteAdmin(route.params.id)
        await router.push(`/super-admins`);
    } catch (error) {
        console.log(error)
    }
}
</script>

<style scoped lang="scss">
.create-admin {
    &__header {
        margin-top: 24px;
    }

    &__header-row {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__delete-admin {
        height: 32px !important;
        padding: 0 8px;
        border-radius: 8px !important;
        font-size: 14px;
        font-weight: 500;

        &:active {
            background-color: $light-gray-2 !important;
        }
    }

    &__main-block {
        margin-top: 24px;
    }

    &__settings-block {
        margin-top: 24px;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        &:first-child {
            width: 34.63%;

            &:active {
                background-color: $gray !important;
            }
        }

        &:last-child {
            width: 60.9%;
        }
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__modal-delete-admin {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }
}
</style>
